import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function EditEcnDialog(data) {
    const [field, setField] = React.useState();
    const { t/*, i18n*/ } = useTranslation();

    const handleClose = () => {
        setField();
    };

    React.useEffect(() => {
        setField(data.field);
    },
    [data.field])

    return field ?
        <React.Fragment>
            <Dialog
                open={field != null}
                onClose={handleClose}
                sx={{
                    '& .MuiDialog-paper': { // Personalizza il contenitore del dialog
                        width: '500px', // Imposta una larghezza fissa (override)
                        maxWidth: '90%', // Larghezza massima relativa alla finestra
                    },
                }} 
            >
                <DialogTitle>Modifica Attributo {field.type} {t(field.type + (field.type === "rds" ? ".table" : "") +"."+field.name)} </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Modifica il Valore
                    </DialogContentText>

                    <TextField
                        sx={{ mt: 3 }}
                        autoFocus
                        size="md"
                        fullWidth
                        onChange={(e) => {
                            let f = { ...field };
                            f.value = e.target.value;
                            setField(f);
                        }}
                        value={field.value}
                    />

                </DialogContent>
                <DialogActions>
                    <Button color='inherit' onClick={handleClose} variant='outlined'>Annulla</Button>
                    <Button variant='contained' onClick={() => data.onUpdate(field)}>Modifica</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
        : <></>
}